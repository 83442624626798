// extracted by mini-css-extract-plugin
export var blockContainer = "request-demo-module--block-container--313d5";
export var form = "request-demo-module--form--a6e6f";
export var formContainer = "request-demo-module--form-container--03208";
export var formHeading = "request-demo-module--form-heading--942ad";
export var formImage = "request-demo-module--form-image--cb5e0";
export var formImageContainer = "request-demo-module--form-image-container--4b16f";
export var infoPanel = "request-demo-module--info-panel--83e18";
export var infoPanelMobile = "request-demo-module--info-panel-mobile--aeb0e";
export var logos = "request-demo-module--logos--f2231";
export var root = "request-demo-module--root--10757";