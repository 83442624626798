import React, { useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Form, Button, Spinner } from 'react-bootstrap';

import { useHubspotForm, useMountEffect } from '../../../../hooks/v2';

import * as styles from './executive-dinner-series-form.module.scss';

const FORM_ID = '77d6eb73-d5cb-4c98-acb2-391ee79083d4';
const WAITLIST_FORM_ID = '75a446c5-6e91-48ee-817f-da810d86c07c';

const INITIAL_STATE = {
  company: { error: null, value: '' },
  dietary_restrictions: { error: null, value: '' },
  email: { error: null, value: '' },
  event_city: { error: null, value: '' },
  firstname: { error: null, value: '' },
  lastname: { error: null, value: '' },
};

export const UqExecutiveDinnerSeriesForm = forwardRef(
  function ExecutiveDinnerSeriesForm(props, ref) {
    const { xs } = useBreakpoint();

    const emailRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const cityRef = useRef(null);
    const companyRef = useRef(null);
    const restrictionsRef = useRef(null);

    const hubspotForm = useHubspotForm(INITIAL_STATE, props.city ? FORM_ID : WAITLIST_FORM_ID);
    const { apiState, reset, setValue, state, submit } = hubspotForm;

    useEffect(() => {
      if (apiState === 'success') {
        props.onSuccess();
      }
    }, [apiState]);

    useImperativeHandle(ref, () => ({ reset }));

    useMountEffect(() => {
      setValue('event_city', props.city || '');

      if (!xs) {
        const focused = props.city ? emailRef : firstNameRef;
        focused.current?.focus({ preventScroll: true });
      }
    });

    const isWaitlist = !props.city;

    return (
      <Form>
        {renderInput('email', 'Email*', emailRef)}
        {isWaitlist && (
          <>
            {renderNameFields()}
            {renderInput('company', 'Company Name*', companyRef)}
            {renderInput('event_city', 'City*', cityRef)}
          </>
        )}
        <Form.Group>
          <Form.Label>Dietary Restrictions</Form.Label>
          <Form.Control
            ref={restrictionsRef}
            as="textarea"
            rows={2}
            type="text"
            className={styles.dietaryRestrictions}
            value={state['dietary_restrictions'].value}
            onChange={(evt) => setValue('dietary_restrictions', evt.target.value)}
          />
        </Form.Group>
        <Button
          className={styles.submitButton}
          onClick={submit}
        >
          {apiState !== 'pending'
            ? (
              <span>{props.city ? 'I\'ll be there!' : 'Join the waitlist!'}</span>
            )
            : (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
              />
            )}
        </Button>
      </Form>
    );

    function renderNameFields() {
      return (
        <>
          {renderInput('firstname', 'First Name*', firstNameRef)}
          {renderInput('lastname', 'Last Name*', lastNameRef)}
        </>
      );
    }

    function renderInput(key, label, ref) {
      const field = state[key];

      return (
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Form.Control
            ref={ref}
            required
            type="text"
            isInvalid={!!field.error}
            value={field.value}
            onChange={(evt) => setValue(key, evt.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            This field is required.
          </Form.Control.Feedback>
        </Form.Group>
      );
    }
  },
);

UqExecutiveDinnerSeriesForm.propTypes = {
  city: PropTypes.string,
  onSuccess: PropTypes.func,
};
