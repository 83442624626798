import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';

import { UqCustomerLogos, UqPage, UqLayoutV2, UqStatsRow, UqQuote } from '@uq-components';
import { Content } from '../content/v2/request-demo';
import { UqRequestDemoForm } from '../components/v2/forms';
import { UqRequestDemoTitle } from '../containers/v2/request-demo';

import * as styles from './request-demo.module.scss';

export default function UqRequestDemoPage() {
  const formRef = useRef(null);

  const formClasses = {
    formContainer: styles.formContainer,
    heading: styles.formHeading,
    image: styles.formImage,
    imageContainer: styles.formImageContainer,
    root: styles.form,
  };

  useEffect(() => {
    return () => formRef.current.reset();
  }, []);

  return (
    <UqPage
      config={{
        seo: {
          description: `See unitQ in action and discover how real-time insights
          from user feedback can help you drive growth, reduce churn, and build loyalty.`,
          title: 'Request a Demo',
        },
      }}
    >
      <UqLayoutV2>
        <Container className="new-uq-theme">
          <section className={styles.root}>
            <article>
              <UqRequestDemoTitle />
              {renderStatsRow()}
            </article>
            <aside>
              <UqRequestDemoForm ref={formRef} classes={formClasses} />
            </aside>
          </section>
        </Container>
        <Container className="new-uq-theme">
          {renderStatsRow(true)}
        </Container>
        <section className={styles.logos}>
          <UqCustomerLogos />
        </section>
      </UqLayoutV2>
    </UqPage>
  );

  function renderStatsRow(isMobile = false) {
    const blockClassName = classNames(styles.blockContainer, {
      [styles.infoPanelMobile]: isMobile,
      [styles.infoPanel]: !isMobile,
    });

    return (
      <div className={blockClassName}>
        <UqStatsRow stats={Content.stats} separate />
        <UqQuote authorProps={Content.quote.author}>
          {Content.quote.text}
        </UqQuote>
      </div>
    );
  }
}
