import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Spinner } from 'react-bootstrap';

import { useEmbeddedForm, useEventListener, useExternalScript } from '../../../hooks/v2';

import * as styles from './hubspot-form.module.scss';

const HS_FORM_EVENT_TYPE = 'hsFormCallback';
const HS_FORM_EVENT_SUBMIT = 'onFormSubmitted';

const FORM_PREFIX = 'uq-form__';

const HUBSPOT_SCRIPT_ID = 'hs-form';
const HUBSPOT_SCRIPT_URL = 'https://js.hsforms.net/forms/v2.js';

UqHubspotForm.propTypes = {
  id: PropTypes.string,
  onSubmitted: PropTypes.func,
};

export function UqHubspotForm(props) {
  const [isPending, setPending] = useState(true);
  const formId = `${FORM_PREFIX}${props.id}`;

  const {
    cleanupScript,
    loadScript,
  } = useExternalScript(HUBSPOT_SCRIPT_ID, HUBSPOT_SCRIPT_URL);

  const initForm = useEmbeddedForm(props.id, formId);

  const onFormReady = useCallback(() => {
    initForm();

    // smooth the load of the form element
    setTimeout(() => {
      setPending(false);
    }, 300);
  }, [initForm]);

  useEventListener(null, 'message', event => {
    if (event.data.type !== HS_FORM_EVENT_TYPE) {
      return;
    }

    if (event.data.eventName === HS_FORM_EVENT_SUBMIT) {
      props.onSubmitted();
    }
  });

  useEffect(() => {
    const script = loadScript();

    script.addEventListener('load', onFormReady);

    return () => {
      cleanupScript();
      script.removeEventListener('load', onFormReady);
    };
  }, []);

  return (
    <article className={styles.root}>
      {isPending && (
        <section className={styles.pending}>
          <Spinner
            animation="border"
            variant="primary"
          />
        </section>
      )}
      <section
        id={formId}
        className={
          classNames(
            styles.form,
            { [styles.pending]: isPending })
        }
      />
    </article>
  );
}
