import React from 'react';
import classNames from 'classnames';

import { UqLogo } from '../../../components/v2';
import { Content } from '../../../content/v2/request-demo';

import * as styles from './request-demo-title.module.scss';

export function UqRequestDemoTitle() {
  const subtitleClasses = classNames('subtitle', styles.subtitle);

  return (
    <>
      <section className={styles.logo}>
        <UqLogo height="79" width="204" />
      </section>
      <h1 className={styles.title}>
        {Content.title}
      </h1>
      <p className={subtitleClasses}>
        {Content.subtitle}
      </p>
      <p className={styles.caption}>
        {Content.caption}
      </p>
    </>
  );
}
